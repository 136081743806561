// export const API_URL = 'http://localhost:1341'
export const API_URL = window?.location?.origin === 'http://localhost:3001' ? 'http://localhost:1342' : window?.location?.origin === 'https://devadmin.ionica.world' ? 'https://devapi.ionica.world' :'https://api.ionica.world'
export const MAP = 'https://maps.googleapis.com/maps/api/geocode/json'
export const KEY_MAP = 'AIzaSyDCRVPqPsZGx7fYVZOTSO40vVs-ARIpWPI'
export const API_URL_SOKET = window?.location?.origin === 'http://localhost:3001' ? 'wss://localhost:1342' : window?.location?.origin === 'wss://devapi.ionica.world' ? 'wss://devapi.ionica.world' : "wss://api.ionica.world"
//export const API_URL_SOKET = "wss://devapi.ionica.world";

/**
 * 
Info de Usuario /user/:id
Booking /booking/user/:id
Profits /profits/user/:id
Suites del Partner en el admin /suite/user/:id

 */

export const ENDPOINTS = {
  LOGIN: "/user/admin/login",
  USER: "/user",
  ADMINS: "/user/admin",
  USER_INFO: "/user/info",
  SETTINGS: "/settings/general",
  SETTINGS_SPECIAL_REGUEST: "/settings/special-request",
  SETTINGS_PRIVACY_POLICIES: "/settings/privacy-policies",
  FAQ: "/settings/faq",
  QUICKANSWERS: "/settings/quickanswers",
  LANGUAGES: "/language",
  CITIES: "/settings/city",
  APARTMENTS: "/project",
  AMENITIES: "/amenities",
  SUITESBYPROJECT: "/project/suite",
  SUITESBYPROJECTCALENDAR: "/project/suite-calendar",
  SUITES: "/suite",
  SUITESALL: "/suite/all",
  SUITESIMG: "/suite-img",
  SUITES_PROFITS: "/profits/suite",
  SUITES_STATISTICS: "/statistics-v2",
  SUITE_CALENDAR: "/schedule/admin",
  SUITE_CALENDAR_DAY: "/schedule/day",
  SUITE_CALENDAR_MONTH: "/schedule/month",
  FINANCIAL_INCOME: "/financial/income",
  FINANCIAL_PENALTY: "/financial/penalty",
  BOOKINGS: "/booking/admin",
  BOOKINGS_STATUS: "/booking/status",
  PROFILEUSER: "/user",
  PROFILEUSER_BOOKINGS: "/booking/user",
  PROFILEUSER_PROFITS: "/profits/user",
  PROFILEUSER_SUITES: "/suite/user",
  DASHBOARD: "/dashboard",
  PAYOUTS: "/payouts",
  PAYOUTS_POST: "/payouts-pay",
  BOOKINGS_CALCULATE: "/booking/calculate"
};
